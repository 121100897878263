import type Stripe from "stripe";

interface IExtendedStripePrice extends Stripe.Price {
    product: Stripe.Product;
}

interface IExtendedStripeSubscriptionItem extends Stripe.SubscriptionItem {
    price: IExtendedStripePrice;
}

interface IExtendedStripeSubscriptionSchedule extends Stripe.SubscriptionSchedule {
    phases: IExtendedStripeSubscriptionSchedulePhase[];
}

interface IExtendedStripeSubscriptionSchedulePhaseItem extends Stripe.SubscriptionSchedule.Phase.Item {
    price: Stripe.Price;
}

interface IExtendedStripeSubscriptionSchedulePhase extends Stripe.SubscriptionSchedule.Phase {
    items: IExtendedStripeSubscriptionSchedulePhaseItem[];
}

export interface IExtendedStripeSubscription extends Stripe.Subscription {
    schedule: IExtendedStripeSubscriptionSchedule;
    items: Stripe.ApiList<IExtendedStripeSubscriptionItem>;
    default_payment_method: Stripe.PaymentMethod;
    current_billing_interval: Stripe.Price.Recurring.Interval;
    scheduled_billing_interval: Stripe.Price.Recurring.Interval;
    latest_invoice: Stripe.Invoice;
}
